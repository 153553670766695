$instagram-color: #20639b;

$link-light: #97c5f4;

$blog-orange: #ce6929;
$catalan-red: #ed2024;
$catalan-yellow: #faa51a;

.blog-orange-bg {
    background-color:$blog-orange;
}

.blog-orange-highlight {
    background-color: transparentize($color: $blog-orange, $amount: .5);
}

.blog-orange {
    color:$blog-orange;
}


$blog-light-orange: #ff7d2b;
$shadow-orange: #c65300;

$link-blue: rgb(83, 140, 198);

$color-gold: #cca24f;
$color-gold-lighter: #f8e9cd;
$color-gold-gradient: linear-gradient(180deg, rgba(204,162,79,1) 0%, rgba(248,233,205,1) 100%);
$color-gold-gradient-dark: linear-gradient(36deg, rgba(204,162,79,1) 0%, rgb(218, 201, 170) 100%);

$color-austrian-red: #ed2939;
$color-se-blue: #29367b;
