@import "color-schemes";

.value-box
{
    .value-box__icon {
        font-size: 36px;
    }
    .value-box__title {
        margin: 10px 0 0;
    }
    .value-header {
        color: white;
        background: black;
        padding: 20px 30px;
        border-radius: 10px 10px 0 0;
        display: inline-block;
    } 
    .value-box__description {
        font-family: Lora, serif;
        font-size: 16px;
        line-height: 1.35;
        margin-bottom: 30px;
        padding: 20px;
        border-radius: 0 0 0 45px;
        border: 2px dotted;
        border-top-color: $color-austrian-red;
        border-right-color: $color-gold;
    }
}
